.line-chart {
  height: 100%;

  &__chart-container {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    min-height: 200px;
  }
}
