.highlight-annotation {
  position: absolute;
  pointer-events: auto;
  cursor: pointer;
  opacity: 0.3;
  padding: 0;
  margin: 0;
  z-index: 102;
}

.annotation-container {
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
}
