.delete-widget-series-field-icon {
  opacity: 0;
  color: red;
}

.widget-series-field:hover .delete-widget-series-field-icon {
  opacity: 0.2;
}

.widget-series-field:hover .delete-widget-series-field-icon:hover {
  opacity: 1;
}
