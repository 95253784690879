.document-container {
  background-color: #f0f3f5;
  height: 100%;
  max-height: 100%;
}

.pages-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
