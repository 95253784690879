@mixin toast-base {
  display: flex;
  min-height: 40px;
  gap: var(--space-8);
  box-shadow: var(--shadow-toast);
  padding: var(--space-8) var(--space-16);
  border-radius: var(--border-radius-small);
}

@mixin toast-body {
  display: flex;
  margin: 0;
  padding: 0;
  gap: var(--space-8);
  font-family: var(--font-family);
  font-size: var(--font-body3);
  line-height: var(--line-height-body3);
  font-weight: var(--font-weight-regular);
}

@mixin toast-variant-default {
  background: var(--color-bg-gray0);

  .Toastify__toast-body {
    @include toast-body;
    color: var(--color-text-on-light-secondary);

    .Toastify__toast-icon {
      display: flex;
      align-items: center;
      margin: 0;
      width: 24px;
      height: 24px;

      & svg {
        fill: var(--color-text-on-light-primary);
      }
    }
  }

  .toast-close {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;


    &__button {
      color: var(--color-text-on-light-primary);
    }
  }

  .Toastify__progress-bar--default {
    background: var(--color-bg-gray6);
  }
}

@mixin toast-variant($type) {
  background: var(--color-denotive-#{$type}-light);

  .Toastify__toast-body {
    @include toast-body;
    color: var(--color-text-on-#{$type});
  }
  .toast-close {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;

    &__button {
      color: var(--color-denotive-#{$type}-main);
    }
  }

  .Toastify__progress-bar--#{$type} {
    background: var(--color-denotive-#{$type}-main);
  }

  .Toastify__toast-icon {
    display: flex;
    align-items: center;
    margin: 0;
    width: 24px;
    height: 24px;

    & svg {
      fill: var(--color-denotive-#{$type}-main);
    }
  }
}

:global {
  .Toastify__toast-container {
    min-width: 400px;
    max-width: 900px;
    width: max-content;
  }

  .Toastify__toast:has(.action-toast) {
    .toast-close {
      padding-bottom: var(--space-2);
    }
  }

  .Toastify__toast--success {
    @include toast-base;
    @include toast-variant("success");
  }

  .Toastify__toast--info {
    @include toast-base;
    @include toast-variant("info");
  }

  .Toastify__toast--warning {
    @include toast-base;
    @include toast-variant("warning");
  }

  .Toastify__toast--error {
    @include toast-base;
    @include toast-variant("error");
  }

  .Toastify__toast--default {
    @include toast-base;
    @include toast-variant-default;
  }
}
