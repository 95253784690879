@use "../../../components/Ions/styles/tokens/colors";

.generic-edit-field {
  // the legacy app's modals have a z-index of 1050, so we
  // need to set the tooltip's z-index to be visible above that
  &__tooltip {
    z-index: 1051;
  }

  &__warning-icon {
    color: var(--color-denotive-warning-main);
  }
}
