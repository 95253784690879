@use "../../../../../../components/Ions/styles/tokens/colors";

.name-text {
  cursor: pointer;

  &--assigned {
    color: var(--color-denotive-success-main);
  }

  &--unassigned {
    color: var(--color-denotive-error-main);
  }

  &--disabled {
    cursor: default;
  }
}
