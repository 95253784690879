@keyframes bounce-in-from-top {
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(10%);
  }

  70% {
    transform: translateY(-5%);
  }

  85% {
    transform: translateY(2%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-out-to-top {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(10%);
  }

  40% {
    transform: translateY(-5%);
  }

  60% {
    transform: translateY(2%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.bounce-out-to-top {
  animation: bounce-out-to-top 800ms linear forwards;
}

.bounce-in-from-top {
  animation: bounce-in-from-top 800ms linear forwards;
}
