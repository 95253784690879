@use "../../Ions/styles/tokens/borderRadius";
@use "../../Ions/styles/tokens/shadow";
@use "../../Ions/styles/tokens/space";

%list-base {
  background-color: var(--color-bg-gray0);
  padding: var(--space-4) 0;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-small);
  margin: 0;
  gap: var(--space-4);

  &:focus-within,
  &:hover {
    box-shadow: var(--shadow-card-hovered);
  }
}

.list {
  @extend %list-base;
}
