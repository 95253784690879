.emphasize-overlay {
  transition: opacity 1s;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 105;
}

.emphasize-overlay.active {
  opacity: 0.6;
}
