@mixin generate-properties($property-map, $prefix, $util-class: false) {
  @each $name, $value in $property-map {
    & {
      --#{$prefix}-#{$name}: #{$value};
    }

    @if $util-class {
      .#{$prefix}-#{$name} {
        #{$util-class}: var(--#{$prefix}-#{$name});
      }
    }
  }
}
