.grid-card {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
  padding: var(--space-16);
  box-sizing: border-box;
  gap: var(--space-8);

  &--small {
    min-width: 110px;
    max-width: 188px;
    align-items: center;
    flex: 0 0 auto;
  }

  &--large {
    min-width: 300px;
    width: 100%;
    align-items: flex-start;
    flex: 1 1 auto;
  }

  &--expand {
    flex: 1 1 auto;
    width: 100%;
    align-items: flex-start;
    padding: var(--space-16);
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-4);
    max-width: 100%;
    width: 100%;
    min-width: 0;
    height: 24px;
    position: relative;
    border-bottom: 1px solid transparent;

    &--centered {
      justify-content: center;
    }

    &--with-separator {
      height: 32px;
      padding-bottom: var(--space-8);
      border-bottom-color: var(--color-bg-gray3);
    }

    &--hidden {
      visibility: hidden;
      display: none;
    }
  }

  &__title-container-left {
    display: flex;
    align-items: center;
    gap: var(--space-4);
    min-width: 0;
  }

  &__title {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;

    &--centered {
      text-align: center;
    }
  }

  &__tooltip {
    width: fit-content;
    min-width: 0;
    display: flex;
  }

  &__footer {
    min-height: 24px;
    width: 100%;
    border-top: 1px solid transparent;
    box-sizing: border-box;

    &--with-separator {
      border-top-color: var(--color-bg-gray3);
    }
  }
}
