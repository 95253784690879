@use "../../Ions/styles/tokens/space";
@use "../../Ions/styles/tokens/colors";

%list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-4);
  height: 28px;
  width: 100%;
  background-color: var(--color-bg-gray0);
  cursor: pointer;
  user-select: none;
  color: var(--label-color);

  &:focus,
  &:hover,
  &:has(:focus),
  &:has(:focus-within),
  &:has(:hover) {
    background-color: var(--color-bg-gray1);
    color: var(--hover-label-color);
  }

  &:active,
  &:has(:active) {
    background-color: var(--color-bg-gray2);
    color: var(--hover-label-color);
  }

  &:disabled,
  &:has(:disabled) {
    pointer-events: none;
    color: var(--color-text-on-disabled-item);
  }
}

.list-item {
  --label-color: var(--color-text-on-light-secondary);
  --hover-label-color: var(--color-text-on-light-primary);

  @extend %list-item;

  &--selected {
    background-color: var(--color-primary-light2);

    --label-color: var(--color-text-on-light-primary);
  }

  &--alert {
    --label-color: var(--color-text-on-error);
    --hover-label-color: var(--color-text-on-error);
  }

  &__button {
    all: unset;
    padding: var(--space-4) var(--space-16);
    width: 100%;
    align-items: center;
    display: flex;
    gap: var(--space-8);
  }

  &__label {
    width: 100%;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__separator {
    all: unset;
    user-select: none;
    height: 1px;
    border-bottom: 1px solid var(--color-bg-gray3);
    color: var(--color-bg-gray3);
  }
}
