.delete-widget-source-icon {
  opacity: 0;
  color: red;
}

.widget-source-node:hover .delete-widget-source-icon {
  opacity: 0.2;
}

.widget-source-node:hover .delete-widget-source-icon:hover {
  opacity: 1;
}
