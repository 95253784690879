.left {
  transform: rotate(-90deg);
}

.down {
  transform: rotate(180deg);
}

.right {
  transform: rotate(90deg);
}
