@use "../../../Ions/styles/tokens/space";
@use "../../../Ions/styles/tokens/colors";

.grid-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-8);
  padding: var(--space-16);
  height: 100%;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  background-color: var(--color-bg-gray2);

  &--no-bg {
    padding: 0;
    background-color: white;
  }

  &__loading-spinner {
    --spinner-icon-height: 48px;
    --spinner-icon-width: 48px;

    height: var(--spinner-icon-height);
    width: var(--spinner-icon-width);

    & svg {
      height: var(--spinner-icon-height);
      width: var(--spinner-icon-width);
    }
  }

  &__icon {
    color: var(--color-bg-gray5);
    width: 82px;
    height: 52px;
  }
}
