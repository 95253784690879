/* For react-pdf */

.pdf-viewer-container {
  border: 1px solid darkgrey;
  text-align: center;
  margin: 0 auto;
  width: 70%;
}

.pg-viewer-wrapper {
  overflow-y: hidden;
}
