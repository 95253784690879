.overlay-controls {
  bottom: 40px;
  position: absolute;
  background: #1b1e21;
  color: #fff;
  opacity: 0.7;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  z-index: 99999;
}
