@use "../../Ions/styles/tokens/borderRadius";
@use "../../Ions/styles/tokens/colors";

@mixin variant($background-color, $icon-color, $color) {
  background-color: $background-color;
  color: $color;

  & svg {
    color: $icon-color;
  }
}

.alert {
  display: flex;
  gap: var(--space-8);
  padding: var(--space-8) var(--space-16);
  width: 400px;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  flex-direction: row;

  &__variant-icon,
  &__dismiss-button {
    width: 24px;
    height: 24px;
  }

  &__variant-icon {
    &--with-action-button {
      padding: var(--space-2) 0;
    }
  }

  &__with-title {
    display: flex;
    flex-direction: column;
  }

  &__title_row {
    display: flex;
    gap: var(--space-8);
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-self: center;
  }

  &__action-button-container,
  &__dismiss-button-container {
    padding-left: var(--space-16);
    align-self: flex-end;
  }

  &__action-button-container {
    height: 24px;
    padding-bottom: var(--space-4);
  }

  &__dismiss-button-container {
    height: 24px;
  }

  &__action-button {
    white-space: nowrap;
  }

  &__content {
    &--sans-title {
      width: 100%;
      padding: var(--space-2) 0;
    }

    &--with-title {
      padding-left: var(--space-32);
    }

    &--with-action-button {
      padding: var(--space-4) 0;
    }
  }

  &--success {
    @include variant(
      $background-color: var(--color-denotive-success-light),
      $icon-color: var(--color-denotive-success-main),
      $color: var(--color-text-on-success)
    );
  }

  &--info {
    @include variant(
      $background-color: var(--color-denotive-info-light),
      $icon-color: var(--color-denotive-info-main),
      $color: var(--color-text-on-info)
    );
  }

  &--warning {
    @include variant(
      $background-color: var(--color-denotive-warning-light),
      $icon-color: var(--color-denotive-warning-main),
      $color: var(--color-text-on-warning)
    );
  }

  &--error {
    @include variant(
      $background-color: var(--color-denotive-error-light),
      $icon-color: var(--color-denotive-error-main),
      $color: var(--color-text-on-error)
    );
  }
}
