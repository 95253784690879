@use "../../Ions/styles/tokens/typography";
@use "../../Ions/styles/tokens/borderRadius";
@use "../../Ions/styles/tokens/shadow";
@use "../../Ions/styles/tokens/colors";
@use "../../Atoms/Text/Text.module";

.card {
  border-radius: var(--border-radius-medium);
  background: var(--color-bg-gray0);
  border: 1px solid var(--color-bg-gray3);
  box-sizing: border-box;
  font-family: var(--font-family);

  &--interactive {
    &:is(button) {
      text-align: unset;
    }

    cursor: pointer;

    &:hover:not(:has(button:hover, [role="button"]:hover)) {
      border: 1px solid var(--color-primary-main);
      box-shadow: var(--shadow-card-hovered);
    }

    &:active:not(:has(button:active, [role="button"]:active)) {
      box-shadow: var(--shadow-card-pressed);
    }
  }
}
