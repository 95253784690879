.dropdown-menu {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  &__list {
    position: absolute;
    top: 100%;
    right: 0;
    white-space: nowrap;
    z-index: 100;

    &--hidden {
      display: none;
    }

    &--is-button {
      top: calc(100% + 4px);
    }
  }
}
