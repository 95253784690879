@use "../../Ions/styles/tokens/typography";
@use "../../Ions/styles/tokens/borderRadius";
@use "../../Ions/styles/tokens/shadow";
@use "../../Ions/styles/tokens/space";
@use "../../Ions/styles/tokens/colors";
@use "../../Atoms/Text/Text.module";

@mixin button-base(
  $backgroundColor,
  $color,
  $hoverBackgroundColor,
  $hoverColor,
  $disabledBackgroundColor,
  $disabledColor,
  $pressedBackgroundColor,
  $outlineColor: transparent
) {
  @extend %font, .subtitle3;

  background-color: $backgroundColor;
  color: $color;
  border-radius: var(--border-radius-small);
  height: 36px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  outline: 1px solid $outlineColor;

  &:focus,
  &:hover {
    background-color: $hoverBackgroundColor;
    color: $hoverColor;
  }

  &:disabled {
    background-color: $disabledBackgroundColor;
    color: $disabledColor;
    pointer-events: none;
    outline-color: var(--color-bg-gray3);
  }

  &:active {
    background-color: $pressedBackgroundColor;
  }

  &.icon-with-text-padding {
    padding: 0 var(--space-16);
  }
}

@mixin standard-button-base {
  padding: var(--space-8) var(--space-16);
  cursor: pointer;
}

.primary {
  @include button-base(
    $backgroundColor: var(--color-primary-main),
    $color: var(--color-text-on-dark-primary),
    $hoverBackgroundColor: var(--color-primary-light1),
    $hoverColor: var(--color-text-on-dark-primary),
    $disabledBackgroundColor: var(--color-bg-gray3),
    $disabledColor: var(--color-text-on-disabled-item),
    $pressedBackgroundColor: var(--color-primary-dark1)
  );

  &.button-text {
    @include standard-button-base;
  }

  &:not(.loading) {
    &.button-icon {
      color: var(--color-text-on-dark-primary);

      &:disabled {
        color: var(--color-text-on-disabled-item);
      }
    }
  }
}

.secondary {
  @include button-base(
    $backgroundColor: var(--color-bg-gray0),
    $color: var(--color-text-on-light-primary),
    $hoverBackgroundColor: var(--color-bg-gray0),
    $hoverColor: var(--color-primary-main),
    $disabledBackgroundColor: var(--color-bg-gray1),
    $disabledColor: var(--color-text-on-disabled-item),
    $pressedBackgroundColor: var(--color-bg-gray0),
    $outlineColor: var(--color-bg-gray4)
  );

  &.button-text {
    @include standard-button-base;
  }

  &:focus,
  &:hover {
    color: var(--color-primary-main);
    outline-color: var(--color-primary-main);
  }

  &:active {
    color: var(--color-primary-main);
    box-shadow: var(--shadow-button-secondary-pressed);
  }

  &:disabled:not(.loading) {
    color: var(--color-text-on-disabled-item);
  }
}

.alert {
  @include button-base(
    $backgroundColor: var(--color-text-on-dark-primary),
    $color: var(--color-denotive-error-main),
    $hoverBackgroundColor: var(--color-denotive-error-light),
    $hoverColor: var(--color-denotive-error-main),
    $disabledBackgroundColor: var(--color-bg-gray1),
    $disabledColor: var(--color-text-on-disabled-item),
    $pressedBackgroundColor: var(--color-bg-gray0),
    $outlineColor: var(--color-denotive-error-main)
  );

  &.button-text {
    @include standard-button-base;
  }

  &.button-icon:not(.loading) {
    color: var(--color-denotive-error-main);
  }

  &:focus,
  &:hover {
    color: var(--color-denotive-error-main);
  }

  &:active {
    color: var(--color-denotive-error-main);
    box-shadow: var(--shadow-button-alert-pressed);
  }

  &:disabled:not(.loading) {
    color: var(--color-text-on-disabled-item);
  }
}

@mixin text-button-base($color, $textDecoration) {
  @extend %font, .subtitle3;
  @include standard-button-base;

  height: 28px;
  margin: 0;
  padding: var(--space-2) var(--space-8);
  background: none;
  border: none;
  color: $color;
  text-decoration: $textDecoration;
  border-radius: var(--border-radius-small);

  &:active {
    box-shadow: var(--shadow-button-secondary-pressed);
  }
}

.link {
  @include text-button-base($color: var(--color-text-on-link), $textDecoration: underline);

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:not(.loading) {
    color: var(--color-text-on-link);
  }

  &:disabled {
    color: var(--color-text-on-disabled-item);
    text-decoration: underline;
    pointer-events: none;

    &:not(.loading) {
      color: var(--color-text-on-disabled-item);
    }
  }

  &:focus:not(.loading),
  &:hover:not(.loading) {
    color: var(--color-primary-main);
  }
}

.text {
  @include text-button-base($color: var(--color-text-on-light-primary), $textDecoration: none);

  &:focus,
  &:hover {
    color: var(--color-primary-main);
  }

  &:disabled {
    color: var(--color-text-on-disabled-item);
    pointer-events: none;
  }

  &:not(.loading) {
    &:active {
      color: var(--color-primary-main);
    }

    &:disabled {
      color: var(--color-text-on-disabled-item);
    }
  }
}

.button-contents-grid {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-8);
}

.icon-only-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon-only-spinner {
  position: absolute;
}

.hidden {
  visibility: hidden;
}
