.artifact-download-container {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  width: 100%;
  margin-bottom: 0.5em;
}

.artifact-download-interval-alert {
  flex: 1;
}
