.delete-data-grid-view-icon {
  opacity: 0;
}
/* stylelint-disable-next-line selector-class-pattern */
.MuiMenuItem-root:hover .delete-data-grid-view-icon {
  opacity: 0.2;
}
/* stylelint-disable-next-line selector-class-pattern */
.MuiMenuItem-root:hover .delete-data-grid-view-icon:hover {
  opacity: 1;
}
