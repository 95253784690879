@use "../../Ions/styles/tokens/colors";
@use "../../Ions/styles/tokens/borderRadius";
@use "../../Ions/styles/tokens/space";
@use "../../Ions/styles/tokens/shadow";
@use "../../Ions/styles/tokens/typography";

.radar-chart {
  &__grid-label {
    text-anchor: middle;
    dominant-baseline: middle;
    font-family: var(--font-family);
    font-size: var(--font-caption);
    fill: var(--color-text-on-light-secondary);
  }
}

.tooltip {
  display: flex;
  flex-direction: column;

  &__title {
    display: block;
    margin-bottom: var(--space-12);
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: var(--space-8);
  }

  &__swatch {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
  }
}
