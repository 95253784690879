@use "../../Ions/styles/tokens/borderRadius";
@use "../../Ions/styles/tokens/colors";

.icon-button {
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-small);

  &--light {
    color: var(--color-text-on-light-primary);

    &:focus-visible,
    &:hover {
      background-color: var(--color-bg-gray2);
    }

    &:active {
      background-color: var(--color-bg-gray3);
    }
  }

  &--dark {
    color: var(--color-text-on-dark-primary);

    &:focus-visible,
    &:hover {
      background-color: var(--color-primary-light1);
    }

    &:active {
      background-color: var(--color-primary-dark1);
    }
  }
}
