.tooltip-icon {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  &--small {
    width: var(--space-16);
    height: var(--space-16);
  }

  &--large {
    width: var(--space-24);
    height: var(--space-24);
  }
}
