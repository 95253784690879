.page-container {
  height: 100%;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  position: relative;
}

.annotations-layer {
  position: absolute;
  top: 0;
  z-index: 101;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.text-layer {
  position: absolute;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
}

.text-layer > span {
  position: absolute;
  white-space: pre;
  cursor: text;
  user-select: text;
}
