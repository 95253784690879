$legacy-app-modal-z-index: 1050;

.scribe-tutorial {
  &__button-container {
    position: fixed;
    bottom: 48px;
    right: 24px;
    z-index: $legacy-app-modal-z-index + 2;
  }

  &__overlay {
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 50%);
    justify-content: center;
    align-items: center;
    z-index: $legacy-app-modal-z-index + 1;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    display: none;

    &--visible {
      display: flex;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  &__container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
    width: 90%;
    max-width: 1200px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__header {
    padding: 16px 24px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0;
  }

  &__close-button {
    padding: 4px;
  }

  &__content {
    flex: 1;
    padding: 0;
    overflow: hidden;
  }

  &__iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
