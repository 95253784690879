@use "../../Ions/styles/tokens/colors";
@use "../../Ions/styles/tokens/typography";

.grid-label {
  font-size: var(--font-caption);
  font-family: var(--font-family);
  fill: var(--color-text-on-light-secondary);
  dominant-baseline: middle;

  &--no-pointer {
    pointer-events: none;
  }
}
