.delete-widget-series-icon {
  opacity: 0;
  color: red;
}

.widget-series-content:hover .delete-widget-series-icon {
  opacity: 0.2;
}

.widget-series-content:hover .delete-widget-series-icon:hover {
  opacity: 1;
}
