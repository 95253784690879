.card-footer__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  padding-top: var(--space-8);
  gap: var(--space-8);
  overflow: hidden;
  box-sizing: content-box;
}

.card-footer__text {
  text-wrap: nowrap;
}
