:root {
  /* Font Sizes */
  --font-h1: 64px;
  --font-h2: 48px;
  --font-h3: 40px;
  --font-h4: 32px;
  --font-h5: 24px;
  --font-h6: 20px;
  --font-subtitle1: 18px;
  --font-subtitle2: 16px;
  --font-subtitle3: 14px;
  --font-body1: 18px;
  --font-body2: 16px;
  --font-body3: 14px;
  --font-link: 14px;
  --font-caption: 13px;

  /* Line Heights */
  --line-height-h1: 64px;
  --line-height-h2: 48px;
  --line-height-h3: 40px;
  --line-height-h4: 40px;
  --line-height-h5: 32px;
  --line-height-h6: 32px;
  --line-height-subtitle1: 22px;
  --line-height-subtitle2: 20px;
  --line-height-subtitle3: 20px;
  --line-height-body1: 22px;
  --line-height-body2: 20px;
  --line-height-body3: 20px;
  --line-height-link: 20px;
  --line-height-caption: 16px;

  /* Font Weights */
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-regular: 400;

  /* Font Families */
  --font-family: "Inter", arial, sans-serif;

  /* Paragraph Spacing */
  --paragraph-spacing-h1: 64px;
  --paragraph-spacing-h2: 48px;
  --paragraph-spacing-h3: 40px;
  --paragraph-spacing-h4: 40px;
  --paragraph-spacing-h5: 32px;
  --paragraph-spacing-h6: 32px;
  --paragraph-spacing-subtitle1: 22px;
  --paragraph-spacing-subtitle2: 20px;
  --paragraph-spacing-subtitle3: 20px;
  --paragraph-spacing-body1: 22px;
  --paragraph-spacing-body2: 20px;
  --paragraph-spacing-body3: 20px;
  --paragraph-spacing-link: 20px;
  --paragraph-spacing-caption: 16px;
}
