%styleless-button {
  all: unset;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
}

.styleless-button {
  @extend %styleless-button;
}
