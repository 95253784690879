@use "../../Ions/styles/tokens/typography";
@use "../../Ions/styles/tokens/colors";

.font,
%font {
  font-family: var(--font-family);
  letter-spacing: 0;

  &.body1 {
    background: none;
    font-size: var(--font-body1);
    line-height: var(--line-height-body1);
    font-weight: var(--font-weight-regular);
  }

  &.body2 {
    background: none;
    font-size: var(--font-body2);
    line-height: var(--line-height-body2);
    font-weight: var(--font-weight-regular);
  }

  &.body3 {
    background: none;
    font-size: var(--font-body3);
    line-height: var(--line-height-body3);
    font-weight: var(--font-weight-regular);
  }

  &.caption {
    background: none;
    font-size: var(--font-caption);
    line-height: var(--line-height-caption);
    font-weight: var(--font-weight-regular);
  }

  &.subtitle1 {
    background: none;
    font-size: var(--font-subtitle1);
    line-height: var(--line-height-subtitle1);
    font-weight: var(--font-weight-semibold);
  }

  &.subtitle2 {
    background: none;
    font-size: var(--font-subtitle2);
    line-height: var(--line-height-subtitle2);
    font-weight: var(--font-weight-semibold);
  }

  &.subtitle3 {
    background: none;
    font-size: var(--font-subtitle3);
    line-height: var(--line-height-subtitle3);
    font-weight: var(--font-weight-semibold);
  }
}

p.body1 {
  margin-bottom: var(--paragraph-spacing-body1);
}

p.body2 {
  margin-bottom: var(--paragraph-spacing-body2);
}

p.body3 {
  margin-bottom: var(--paragraph-spacing-body3);
}

p.caption {
  margin-bottom: var(--paragraph-spacing-caption);
}

p.subtitle1 {
  margin-bottom: var(--paragraph-spacing-subtitle1);
}

p.subtitle2 {
  margin-bottom: var(--paragraph-spacing-subtitle2);
}

p.subtitle3 {
  margin-bottom: var(--paragraph-spacing-subtitle3);
}
