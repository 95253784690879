@use "../../Ions/styles/tokens/colors";
@use "../../Ions/styles/tokens/space";
@use "../../Ions/styles/tokens/borderRadius";

.tooltip__content {
  color: var(--color-text-on-dark-primary);
  padding: var(--space-6) var(--space-8);
  border-radius: var(--border-radius-small);
  width: max-content;
  max-width: 300px;
  background-color: var(--color-bg-transparent-dark1);
  box-shadow: var(--shadow-tooltip);
  animation-duration: 250ms;
  animation-timing-function: ease-out;
  user-select: none;
  will-change: transform, opacity;

  &[data-state="delayed-open"][data-side="top"] {
    animation-name: slide-down-and-fade;
  }

  &[data-state="delayed-open"][data-side="right"] {
    animation-name: slide-left-and-fade;
  }

  &[data-state="delayed-open"][data-side="bottom"] {
    animation-name: slide-up-and-fade;
  }

  &[data-state="delayed-open"][data-side="left"] {
    animation-name: slide-right-and-fade;
  }
}

.tooltip__arrow {
  fill: var(--color-bg-transparent-dark1);
}

@keyframes slide-up-and-fade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-right-and-fade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-down-and-fade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-left-and-fade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
