.container-document {
  margin: 1em 0;
}

.container-document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgb(0 0 0 / 50%);
  margin: 1em;
}

.container-document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.container-document .react-pdf__message {
  padding: 20px;
  color: white;
}
