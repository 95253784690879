.raw-data-container {
  height: 70vh;
  display: flex;
  flex-direction: column;

  &__no-data-alert {
    width: 100%;
  }

  &__data-grid {
    flex: 1;
  }
}
