/* Import Simple Line Icons Set */
@import "../../../../node_modules/simple-line-icons/css/simple-line-icons.css";

/* Import Font Awesome Icons Set */
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";

/* Import Main styles for this application */
@import "../../../scss/style";
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-layouts/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-querybuilder/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-lists/styles/material.css";
@import "../../../../node_modules/react-perfect-scrollbar/dist/css/styles.css";
