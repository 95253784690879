.ribbon-parent {
  overflow: hidden; /* required */
}

.ribbon {
  margin: 0;
  padding: 0;
  background: rgb(7 189 234);
  color: white;
  position: absolute;
  opacity: 1;
  top: 0;
  right: 0;
  height: 2em;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}

.ribbon::before,
.ribbon::after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0; /* tweak */
  width: 100%;
  height: 100%;
  opacity: 1;
  background: rgb(7 189 234);
}

.ribbon::before {
  right: 100%;
}

.ribbon::after {
  left: 100%;
}
