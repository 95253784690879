@use "../../../Ions/styles/tokens/space";
@use "../../../Ions/styles/tokens/borderRadius";
@use "../../../Ions/styles/tokens/colors";

.legend {
  display: flex;
  flex-flow: row wrap;
  gap: var(--space-8);
  justify-content: flex-end;

  &__item {
    display: flex;
    flex: 1;
    min-width: 40px;
    max-width: min-content;
    align-items: center;
    gap: var(--space-4);
  }

  &__swatch {
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    border-radius: var(--border-radius-full);
  }

  &__text {
    overflow: hidden;
    min-width: 26px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-text-on-light-secondary);
  }
}
